import { withTranslations } from 'react-utilities';
import React, { Fragment, useCallback } from 'react';
import { createSystemFeedback } from 'react-style-guide';
import PlayerFeedbackBanner from './PlayerFeedbackBanner';
import { playerFeedbackTranslationConfig } from '../translation.config';
import useExperiment from '../utils/useExperiment';
import { ReviewCategoryType } from '../services/playerFeedbackService';

export type PlayerFeedbackContainer = {
  show: boolean;
  voteType?: ReviewCategoryType;
  placeName: string;
  placeId: string;
};

const PlayerFeedbackContainer: React.FC<PlayerFeedbackContainer> = props => {
  const { data } = useExperiment('ExperienceDetailsPage.PlayerFeedback');
  const [SystemFeedback, systemFeedbackService] = createSystemFeedback();

  const showSuccessMessage = useCallback(
    (message: string) => systemFeedbackService.success(message),
    [systemFeedbackService]
  );

  if (!data?.EnablePlayerFeedbackWebMvp) {
    return null;
  }
  return (
    <Fragment>
      <div className='player-feedback-alert-container'>
        <SystemFeedback />
      </div>
      <PlayerFeedbackBanner {...props} showSuccessMessage={showSuccessMessage} />
    </Fragment>
  );
};

export default withTranslations(PlayerFeedbackContainer, playerFeedbackTranslationConfig);
