import { EnvironmentUrls } from 'Roblox';

const gameDetailsPlayerFeedbackBannerContainerId = 'game-details-feedback-banner-container';
const gameDetailsPlayerFeedbackBannerContainer = (): HTMLElement | null =>
  document.getElementById(gameDetailsPlayerFeedbackBannerContainerId);
const tosUrl = `${EnvironmentUrls.websiteUrl}/info/terms`;

export default {
  gameDetailsPlayerFeedbackBannerContainerId,
  gameDetailsPlayerFeedbackBannerContainer,
  tosUrl
};
