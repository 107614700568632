/* eslint-disable camelcase */ // needed for ChannelInformation type, which is a mirror of the backend
import { AxiosPromise, httpService } from 'core-utilities';
import { EnvironmentUrls } from 'Roblox';

const { apiGatewayUrl } = EnvironmentUrls;

export enum ReviewCategoryType {
  Undefined = 0,
  Upvote = 1,
  Downvote = 2
}

export type TReviewErrorResponse = {
  data: {
    code: string;
    detail: string;
    message: string;
  };
};

export type TChannelInformation = {
  data: {
    capabilities: {
      can_submit_category_options: boolean;
      can_submit_comment: boolean;
      can_submit_review: boolean;
    };
    categories: [
      {
        type: string;
        label: string;
      }
    ];
    comment: {
      label: string;
      placeholder_text: string;
      minimum_length: number;
      maximum_length: number;
    };
    metadata: {
      form_title_label: string;
      disclaimer_label: string;
      submit_button_label: string;
    };
  };
};

const playerFeedbackApiUrl = `${apiGatewayUrl}/player-generated-reviews-service/v1/channels/experience-discovery-page/assets`;

export const submitReview = (
  rootPlaceId: string | number,
  reviewCategory: ReviewCategoryType,
  reviewText: string
): AxiosPromise => {
  const url = `${playerFeedbackApiUrl}/${rootPlaceId}/reviews`;

  const urlConfig = {
    withCredentials: true,
    url
  };

  const params = {
    comment: reviewText,
    category_type: reviewCategory,
    category_option_ids: []
  };

  return httpService.post(urlConfig, params);
};

export const getChannelInformation = (
  rootPlaceId: string | number
): Promise<TChannelInformation> => {
  const url = `${playerFeedbackApiUrl}/${rootPlaceId}`;

  const urlConfig = {
    withCredentials: true,
    url
  };

  return httpService.get(urlConfig);
};
